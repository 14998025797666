import { useState, useMemo } from 'react';

import ButtonViewMore from '../ButtonViewMore/ButtonViewMore';
import styles from './Meals.module.css';

export default function Meals({ recommendedMeals }) {
  const [isOpen, setIsOpen] = useState(false);

  // Determine which meals to display based on whether "View More" is toggled
  const displayedMeals = useMemo(() => {
    return isOpen ? recommendedMeals : recommendedMeals.slice(0, 5);
  }, [isOpen, recommendedMeals]);

  return (
    <div className={styles.meals}>
      {displayedMeals.map((meal) => (
        <RecommendedMeal {...meal} key={meal.id} />
      ))}
      <ButtonViewMore isOpen={isOpen} setIsOpen={setIsOpen} toppingsAmount={10} />
    </div>
  );
}

function RecommendedMeal({ name, img, price, id }) {
  return (
    <label htmlFor={id} className={styles.meal}>
      <input id={id} type="checkbox" />
      <img className={styles.img} src={img} alt={name} />
      <p className={styles.name}>{name}</p>
      <p className={styles.price}>{`+฿ ${price}`}</p>
    </label>
  );
}
