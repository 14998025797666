const testAddress = {
  street: 'Street Name',
  city: 'City Name',
  floor: 'Floor Number',
};

const tipOptions = [
  { value: 0, id: 'ek2h3pf' },
  { value: 5, id: 'kjhmn4q' },
  { value: 10, id: 'grsn11s' },
  { value: 20, id: 'gqmsbot' },
  { value: 40, id: 'r687fza' },
  { value: 60, id: 'qh2yt4s' },
  { value: 80, id: 'rhi4qi9' },
];

const testDeliveryOptions = [
  { type: 'Standard', time: '15-30', price: 0 },
  { type: 'Priority', time: '10-20', price: 30 },
];

export { testAddress, tipOptions, testDeliveryOptions };
